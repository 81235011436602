.stroke-text {
    -webkit-text-stroke: 0.75px #841217;
    text-stroke: 0.75px #841217;
  }

.stroke {
    -webkit-text-stroke: 1px #841217;
    text-stroke: 1px #841217;
}

.custom-font {
  font-family: "Sloop";
}

/* #spin:after {
  content:"";
  animation: spin 15s linear infinite;
}

@keyframes spin {
  0% { content: "a jack of all trades";}
  20% { content: "an explorer";}
  40% { content: "a student";}
  60% { content: "an optimist";}
  80% { content: "a cat lover";}
  100% { content: "an experimentalist";}
} */

.spin {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  opacity: 0;
  animation: slide-in 8s infinite;
}

@keyframes slide-in {
  0% {
      opacity: 0;
  }
  10% {
      opacity: 1;
  }
  20% {
      opacity: 1;
  }
  30%, 100% {
      opacity: 0;
  }
}

#s1 {
  animation-delay: 0s;
}

#s2 {
  animation-delay: 2s;
}

#s3 {
  animation-delay: 4s;
}

#s4 {
  animation-delay: 6s;
}

.mySwiper {
  width: 100%;
  height: 300px; /* Adjust height as needed */
}

.swiper {
  width: 240px;
  height: 300px;
  margin-left: 0;
}

.swiper.swiper-cards.swiper-3d.swiper-initialized.swiper-horizontal.swiper-watch-progress.mySwiper {
  margin-left: 0;
}

.swiper-slide {
  display: flex;
  align-items: start;
  justify-content: start;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}

@media screen and (min-width: 768px) {
  .swiper {
    width: 300px;
    height: 360px;
  }
  
}

@media screen and (min-width: 1028px) {
  .swiper {
    width: 400px;
    height: 500px;
  }
  
}

.navbar-transition {
  transition: left 0.2s ease-in-out;
}
.navbar-hidden {
  left: -100%;
}
.navbar-visible {
  left: 0;
}

.toc-wrapper {
  position: fixed;
  top: 20%;
  right: 0;
  width: 100px;
  height: 80%;
  z-index: 1000;
  transition: all 0.3s ease;
}

.toc-lines {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

.toc-item-line {
  width: 20%;
  height: 2px;
  background-color: #E3E2E0;
  margin-bottom: 8px;
  border-radius: 1px;
  transition: all 0.3s ease;
}

.toc-item-line.active {
  background-color: #333;
}

.toc {
  display: none;
  position: fixed;
  top: 100px;
  right: 0px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 20px;
  overflow-y: auto;
  max-height: 100%;
  border-radius: 12px;
  margin-right: 5px;
}

.toc-wrapper:hover .toc {
  display: block;
}

.toc-wrapper:hover .toc-lines {
  display: none;
}

.toc ul {
  list-style-type: none;
  padding: 0;
}

.toc-item {
  padding: 5px 0;
}

.toc-item a {
  text-decoration: none;
  color: #333;
}

.toc-item a:hover {
  background-color: #d6d6d6;
}

.toc-item.active a {
  color: #007bff;
}

